<template>
  <div>
    <v-card>
      <v-card-title class="d-flex justify-space-between flex-wrap">
        <div>{{ $t('users.create') }}</div>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="success"
            dark
            class="mr-2"
            @click.prevent="save"
          >
            {{ $t('general.save') }}
          </v-btn>

          <router-link :to="{name:'admin-user-list'}">
            <v-btn
              dark
              outlined
              color="error"
              class="mr-2"
            >
              {{ $t('general.close') }}
            </v-btn>
          </router-link>
        </div>
      </v-card-title>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="user.firstName"
                :rules="[validator.required]"
                dense
                outlined
                :label="$t('users.firstName')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="user.lastName"
                :rules="[validator.required]"
                dense
                outlined
                :label="$t('users.lastName')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="user.title"
                dense
                outlined
                :label="$t('users.title')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="user.function"
                dense
                outlined
                :label="$t('users.function')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="user.email"
                :rules="[validator.required, validator.emailValidator]"
                dense
                outlined
                :label="$t('users.email')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="user.correspondenceEmail"
                dense
                outlined
                :label="$t('users.correspondenceEmail')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="user.password"
                type="password"
                dense
                outlined
                :label="$t('users.password')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="user.passwordConfirmation"
                type="password"
                dense
                outlined
                :label="$t('users.passwordConfirmation')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-select
                v-model="user.language"
                :rules="[validator.required]"
                :items="languageOptions"
                item-value="key"
                :item-text="item => $t(item.name)"
                dense
                outlined
                :label="$t('users.language')"
                class="w-full"
              >
              </v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-select
                v-model="user.correspondenceLanguage"
                :items="languageOptions"
                item-value="key"
                :item-text="item => $t(item.name)"
                dense
                outlined
                :label="$t('users.correspondenceLanguage')"
                class="w-full"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="user.mobile"
                dense
                outlined
                :label="$t('users.mobile')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="user.phone"
                dense
                outlined
                :label="$t('users.phone')"
                class="w-full"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-select
                v-model="user.userType"
                :rules="[validator.required]"
                :items="userTypeOptions"
                :item-text="item => $t(item.name)"
                item-value="key"
                :disabled="user.userType === 'clean_green_admin' || user.userType === 'super_admin'"
                :label="$t('users.userType')"
                dense
                outlined
                class="w-full"
              >
              </v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-select
                v-model="user.status"
                :rules="[validator.required]"
                :items="statusOptions"
                :disabled="user.userType === 'clean_green_admin' || user.userType === 'super_admin'"
                :item-text="item => $t(item.name)"
                item-value="key"
                :label="$t('general.status')"
                dense
                outlined
                class="w-full"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-autocomplete
                v-model="user.company"
                dense
                outlined
                :label="$t('general.company')"
                :items="companies"
                item-text="name"
                item-value="id"
                clearable
                type="search"
                @update:search-input="filterCompanies"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title>
                  {{ $t('users.twoFactorVerificationMethod') }}
                </v-card-title>
                <v-card-text>
                  <v-btn
                    value="mail"
                    :label="$t('users.email')"
                    :color="user.twoFactorVerificationMethod === 'mail' ? 'primary' : 'secondary'"
                    class="ma-1"
                    fab
                    dark
                    @click.prevent="user.twoFactorVerificationMethod === 'mail' ?
                      user.twoFactorVerificationMethod = null :
                      user.twoFactorVerificationMethod = 'mail'"
                  >
                    <v-icon dark>
                      {{ icons.mdiEmailAlert }}
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="user.mobile"
                    value="sms"
                    :label="$t('users.mobile')"
                    :color="user.twoFactorVerificationMethod === 'sms' ? 'primary' : 'secondary'"
                    class="ma-1"
                    fab
                    dark
                    @click.prevent="user.twoFactorVerificationMethod === 'sms' ?
                      user.twoFactorVerificationMethod = null :
                      user.twoFactorVerificationMethod = 'sms'"
                  >
                    <v-icon dark>
                      {{ icons.mdiCellphoneKey }}
                    </v-icon>
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-btn
          color="success"
          dark
          class="mr-2"
          @click.prevent="save"
        >
          {{ $t('general.save') }}
        </v-btn>

        <router-link :to="{name:'admin-user-list'}">
          <v-btn
            dark
            outlined
            color="error"
            class="mr-2"
          >
            {{ $t('general.close') }}
          </v-btn>
        </router-link>
      </v-card-actions>
    </v-card>

    <v-snackbar
      v-model="snackbar"
    >
      {{ $t('users.dataSaved') }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('general.close' ) }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiCellphoneKey,
  mdiEmailAlert,
} from '@mdi/js'

import axiosIns from '@axios'
import { emailValidator, passwordValidator, required } from '@core/utils/validation'

export default {
  components: {},
  data() {
    const snackbarMessage = ''
    const userId = null
    const snackbar = false
    const loading = true
    const options = {}
    const user = {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      correspondenceEmail: '',
      phone: '',
      mobile: '',
      language: '',
      correspondenceLanguage: '',
      title: '',
      function: '',
      password: '',
      passwordConfirmation: '',
      userType: '',
      placementAddress: '',
      status: '',
      twoFactorVerificationMethod: '',
      company: '',
    }

    const statusFilter = ''
    const statusOptions = this.$store.state.baseStatuses
    const languageOptions = this.$store.state.languages
    const userTypeOptions = this.$store.state.userTypes

    const companies = []

    return {
      snackbarMessage,
      userTypeOptions,
      languageOptions,
      companies,
      userId,
      snackbar,
      options,
      loading,
      statusFilter,
      user,
      statusOptions,

      icons: {
        mdiCellphoneKey,
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiEmailAlert,
      },

      validator: {
        emailValidator,
        passwordValidator,
        required,
      },
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      axiosIns.get(`/admin/users/${this.$route.params.id}`)
        .then(res => {
          const { user } = res.data

          this.userId = user.id
          this.user.firstName = user.first_name
          this.user.lastName = user.last_name
          this.user.email = user.email
          this.user.correspondenceEmail = user.correspondence_email
          this.user.phone = user.phone
          this.user.mobile = user.mobile
          this.user.language = user.language
          this.user.correspondenceLanguage = user.correspondence_language
          this.user.title = user.title
          this.user.function = user.function
          this.user.userType = user.user_type
          this.user.placementAddress = user.placement_address
          this.user.status = user.status
          this.user.twoFactorVerificationMethod = user.two_factor_verification_method
          this.user.company = user.company_id
          this.companies = [user.company]
        })
        .catch()
    },
    save() {
      if (this.$refs.form.validate()) {
        const user = {
          first_name: this.user.firstName,
          last_name: this.user.lastName,
          email: this.user.email,
          correspondence_email: this.user.correspondenceEmail,
          phone: this.user.phone,
          mobile: this.user.mobile,
          language: this.user.language,
          correspondence_language: this.user.correspondenceLanguage,
          title: this.user.title,
          function: this.user.function,
          password: this.user.password,
          password_confirmation: this.user.passwordConfirmation,
          user_type: this.user.userType,
          placement_address: this.user.placementAddress,
          status: this.user.status,
          two_factor_verification_method: this.user.twoFactorVerificationMethod,
          company_id: this.user.company,
        }

        axiosIns({
          method: this.userId ? 'PUT' : 'POST',
          data: user,
          url: this.userId ? `/admin/users/${this.userId}` : '/admin/users',
        })
          .then(res => {
            this.snackbarMessage = this.$t('users.dataSaved')
            this.snackbar = true
            this.userId = res.data.user.id
          })
          .catch(() => {
            this.snackbarMessage = this.$t('users.validationError')
            this.snackbar = true
          })
      }
    },

    filterCompanies(filter) {
      if (filter && filter.length >= 2) {
        axiosIns.get(`/admin/companies?filter=${filter}`).then(res => {
          this.companies = res.data.companies.data
        }).catch()
      }
    },
  },
}
</script>

<style lang="scss">
</style>
